import React, { useEffect, useState } from "react";
import useApi from "../../../utils/useApi";
import SelectTemplate from "./SelectTemplate";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CircleCheck, LoaderCircle, Store } from "lucide-react";
import { Breadcrumbs, InputField, ListBox } from "../../../components";

export default function NewProject() {
  const { request, isLoading } = useApi();
  const [siteCreated, setCreated] = useState(false);
  const [industry, setIndustry] = useState(null);
  const [templates, setTemplates] = useState([]);
  const merchant = JSON.parse(localStorage.getItem("merchant")) || {};

  // Industries List
  const [industries, setIndustries] = useState([]);
  const getIndustries = async () => {
    try {
      const data = await request({ method: "get", url: "industries" });
      setIndustries(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getIndustries();
  }, []);

  // Website Data
  const [selectedThemeId, setSelectedThemeId] = useState(null);
  const [project_name, setProjectName] = useState("");

  const navigate = useNavigate();
  const manageProject = () => {
    navigate(`/projects/${project_name?.toLowerCase()?.replaceAll(" ", "-")}`);
    setProjectName("");
    setSelectedThemeId(null);
  };
  const manageDomains = () => {
    navigate(
      `/projects/${project_name?.toLowerCase()?.replaceAll(" ", "-")}/domains`
    );
    setProjectName("");
    setSelectedThemeId(null);
  };

  //  Fetch Templates Data
  const getTemplates = async () => {
    if (!industry) return;
    try {
      const data = await request({
        method: "get",
        url: `industries/${industry?._id}/templates?active=1`,
      });
      setTemplates(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (industry?._id) {
      getTemplates();
    }
  }, [industry]);

  const createProject = async (e, template_id) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: "projects",
        data: {
          project_name,
          industry_id: industry?._id,
          industry_template_id: template_id,
          merchant_id: merchant?._id,
        },
      });
      toast.success("New Project Created");
      setCreated(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div
      style={{ backgroundImage: "url('/newProjectBg.jpg')" }}
      className="flex flex-col items-center w-full min-h-[calc(100vh-)]:"
    >
      <Toaster />
      {siteCreated && (
        <div className="h-full w-full fixed top-0 left-0 bg-gray-400/50 dark:bg-gray-950/50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-10 shadow-xl rounded-xl text-center w-5/12 flex flex-col items-center gap-5">
            <CircleCheck className="w-16 text-green-500" />
            <h1 className="font-semibold">Congratulations</h1>
            <p className="text-lg">Your Project is ready.</p>
            <div className="flex items-center justify-center gap-2">
              {" "}
              <button onClick={manageProject} className="btnPrimary">
                Manage Project
              </button>
              <button onClick={manageDomains} className="btnWhite">
                Connect Domain
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <div className="grid grid-cols-1 md:grid-cols-newSite w-full">
        <div className="py-8 px-10 bg-white dark:bg-gray-900">
          <Breadcrumbs />
          <h2 className="text-3xl font-bold capitalize mt-7 md:mt-28 mb-1">
            Create New Project
          </h2>
          <p className="text-gray-500">
            To deploy a new Project, import an existing Git Repository or get
            started with one of our Templates.
          </p>
          <div className="flex flex-col max-w-screen-sm mt-8 gap-5">
            <InputField
              label="What do you want to call this project?"
              placeholder="Enter project name"
              value={project_name}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <ListBox
              label="Select Project Type"
              name="industry"
              options={industries?.map((industry) => ({
                _id: industry._id,
                name: industry.industry_name,
              }))}
              selectedOption={industry}
              setSelectedOption={setIndustry}
              placeholder="Select"
            />
          </div>
        </div>
        <div className="bg-gray-50 dark:bg-gray-800 h-[calc(100vh-3rem)] overflow-y-scroll">
          {industry ? (
            <div className="px-10">
              <h3 className="font-semibold capitalize mt-7 text-lg md:text-2xl">
                Select a {industry?.name} template for your project
              </h3>
              {isLoading ? (
                <div className="flex flex-col items-center w-full p-52 gap-5">
                  <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
                  <p>Loading Templates...</p>
                </div>
              ) : (
                <div className="mt-5 w-full">
                  {templates?.length > 0 ? (
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-5">
                      {templates.map((template, index) => (
                        <SelectTemplate
                          key={index}
                          themeId={template._id}
                          image={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template?._id}/thumb/${template.thumbnail}`}
                          title={template.template_name}
                          demoURL={template?.url}
                          selectedValue={selectedThemeId}
                          setSelectedValue={setSelectedThemeId}
                          createProject={createProject}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-gray-500 py-44">
                      No templates found
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <img
              src="/onboarding.webp"
              className="h-[calc(100vh-5rem)] ml-auto mr-auto"
              alt=""
            />
          )}
        </div>
      </div> */}

      <div className="py-8 px-10 bg-white dark:bg-gray-900 mt-10">
        <Breadcrumbs />
        <h2 className="text-3xl font-bold capitalize mt-7 md:mt-28 mb-1">
          Create New Project
        </h2>
        <p className="text-gray-500">
          To deploy a new Project, import an existing Git Repository or get
          started with one of our Templates.
        </p>
        <div className="flex flex-col max-w-screen-sm mt-8 gap-5">
          <InputField
            label="What do you want to call this project?"
            placeholder="Enter project name"
            value={project_name}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <ListBox
            label="Select Project Type"
            name="industry"
            options={industries?.map((industry) => ({
              _id: industry._id,
              name: industry.industry_name,
            }))}
            selectedOption={industry}
            setSelectedOption={setIndustry}
            placeholder="Select"
          />
        </div>
      </div>
    </div>
  );
}
