import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import usePermissions from "../../../../utils/userPermission";
import { Camera, Check, Edit, LoaderCircle, Trash, X } from "lucide-react";

function useClickOutside(ref, handler) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, handler]);
}

export default function ProjArticleCategories() {
  const { request, isLoading } = useApi();
  const { project_id } = useProjectContext();
  const { hasPermission } = usePermissions();

  const [categories, setCategories] = useState([]);
  const [blogsList, setBlogsList] = useState([]);
  const [input, setInput] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [imagePreviewSrc, setImagePreviewSrc] = useState(null);
  const [selectedCategoryForImageUpload, setSelectedCategoryForImageUpload] =
    useState(null);

  const [fileInfo, setFileInfo] = useState(null);
  const fileInputRef = useRef(null);

  const getCategories = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/categories`,
      });
      const fetchedCategories = res?.data?.[0]?.value || [];
      const categoriesWithIds = fetchedCategories.map((category, index) => ({
        ...category,
        id: index,
      }));
      setCategories(categoriesWithIds);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  }, [project_id]);

  const getBlogList = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/blog_list`,
      });
      setBlogsList(res?.data?.[0]?.value || []);
    } catch (err) {
      console.error("Error fetching blogs list:", err);
    }
  }, [project_id, request]);

  useEffect(() => {
    if (project_id) {
      getCategories();
      getBlogList();
    }
  }, [project_id]);

  const updateCategoriesOnServer = async (updatedCategories) => {
    try {
      const formData = new FormData();
      formData.append("key", "categories");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedCategories));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setCategories(updatedCategories);
      toast.success("Categories updated successfully");
    } catch (error) {
      toast.error("Failed to update categories");
    }
  };

  const addCategory = async (e) => {
    e.preventDefault();
    const trimmedInput = input.trim();
    if (!trimmedInput) {
      toast.error("Category name cannot be empty");
      return;
    }
    if (categories.some((category) => category.title === trimmedInput)) {
      toast.error("Category already exists");
      return;
    }

    let imageFileName = null;
    if (fileInfo && fileInfo.file) {
      try {
        const uniqueId = `cat-${trimmedInput}-${Date.now()}`;
        const formData = new FormData();
        formData.append("key", uniqueId);
        formData.append("value", uniqueId);
        formData.append("file", fileInfo.file);

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (res.status) {
          imageFileName = res.data.file_name;
        } else {
          toast.error("Failed to upload image");
          return;
        }
      } catch (err) {
        const errorMsg =
          err.response?.data?.message ||
          "An error occurred during image upload.";
        toast.error(errorMsg);
        return;
      }
    }

    const newCategory = {
      title: trimmedInput,
      image: imageFileName,
      id: categories.length,
    };
    const updatedCategories = [...categories, newCategory];
    setCategories(updatedCategories);
    setInput("");
    setFileInfo(null);
    await updateCategoriesOnServer(updatedCategories);
  };

  const onEdit = (category) => setEditCategoryId(category.id);
  const onCancelEdit = () => setEditCategoryId(null);

  const onSaveEdit = async (categoryId, newTitle) => {
    if (!newTitle.trim()) {
      toast.error("Category name cannot be empty");
      return;
    }
    const updatedCategories = categories.map((category) =>
      category.id === categoryId ? { ...category, title: newTitle } : category
    );
    setCategories(updatedCategories);
    setEditCategoryId(null);
    await updateCategoriesOnServer(updatedCategories);
  };

  const onDelete = (categoryId, confirmDelete = false) => {
    if (confirmDelete) {
      removeCategory(categoryId);
      setDeleteCategoryId(null);
    } else {
      if (categoryId === null) {
        setDeleteCategoryId(null);
      } else {
        const category = categories.find((c) => c.id === categoryId);
        const categoryExists = blogsList.some(
          (blog) => blog.category === category.title
        );
        if (categoryExists) {
          toast.error(
            `Cannot delete category "${category.title}" - Blogs exist!`
          );
        } else {
          setDeleteCategoryId(categoryId);
        }
      }
    }
  };

  const removeCategory = async (categoryId) => {
    const updatedCategories = categories.filter(
      (category) => category.id !== categoryId
    );
    setCategories(updatedCategories);
    await updateCategoriesOnServer(updatedCategories);
  };

  const onImageUpload = (category) => {
    setSelectedCategoryForImageUpload(category);
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file || !selectedCategoryForImageUpload) return;
    try {
      const uniqueId =
        `cat-${selectedCategoryForImageUpload.title}-${selectedCategoryForImageUpload.id}`
          ?.replaceAll(" ", "-")
          ?.toLowerCase();
      const formData = new FormData();
      formData.append("key", uniqueId);
      formData.append("value", uniqueId);
      formData.append("file", file);

      const res = await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.status) {
        const updatedCategories = categories.map((cat) =>
          cat.id === selectedCategoryForImageUpload.id
            ? { ...cat, image: res.data.file_name }
            : cat
        );
        setCategories(updatedCategories);
        await updateCategoriesOnServer(updatedCategories);
        toast.success("Category Image Updated.");
      }
    } catch (err) {
      const errorMsg =
        err.response?.data?.message || "An error occurred during image upload.";
      toast.error(errorMsg);
    } finally {
      setSelectedCategoryForImageUpload(null);
      fileInputRef.current.value = null;
    }
  };

  const handleNewFile = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setFileInfo({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const onPreviewImage = (category) => {
    const imageUrl = `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${category?.image}`;
    setImagePreviewSrc(imageUrl);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addCategory(e);
    }
  };

  return (
    hasPermission("Project Categories") && (
      <div className="py-6 px-8 flex flex-col items-center">
        <h2 className="mb-3 font-bold">Project Categories</h2>
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-fit">
          {hasPermission("Add Project Category") && (
            <div className="flex items-end gap-2">
              <InputField
                label="New Category"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Add a category and press Enter"
              />
              {fileInfo?.preview && (
                <img
                  src={fileInfo?.preview || ""}
                  alt="Preview"
                  className="w-24 h-10 rounded-md"
                  onClick={() => setImagePreviewSrc(fileInfo?.preview || "")}
                />
              )}
              <input
                type="file"
                onChange={handleNewFile}
                id="imageUpload"
                className="hidden"
                accept="image/*"
              />
              <label
                htmlFor="imageUpload"
                className="border border-gray-300 rounded-md py-2 px-4 cursor-pointer"
              >
                <Camera className="w-5" />
              </label>
              <button
                onClick={addCategory}
                className="btnPrimary px-6 text-base"
              >
                Add
              </button>
            </div>
          )}

          <div className="grid mt-5 divide-x divide-gray-200 dark:divide-white/20 dark:border-white/20 border-b grid-cols-categories">
            {["Sr#", "Category", "Article Count", "Actions", "Image"].map(
              (item, index) => (
                <div
                  key={index}
                  className="tableHead text-gray-500 w-full h-full bg-white dark:bg-gray-800 text-start"
                >
                  {item}
                </div>
              )
            )}
          </div>

          <div className="flex flex-col">
            {isLoading ? (
              <LoaderCircle className="animate-spin h-10 w-10 mx-auto my-12 text-primary" />
            ) : (
              categories.map((category, index) => (
                <CategoryRow
                  key={category.id}
                  category={category}
                  index={index}
                  blogsList={blogsList}
                  isEditing={editCategoryId === category.id}
                  isDeleting={deleteCategoryId === category.id}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onImageUpload={onImageUpload}
                  onPreviewImage={onPreviewImage}
                  onSaveEdit={onSaveEdit}
                  onCancelEdit={onCancelEdit}
                  hasPermission={hasPermission}
                  project_id={project_id}
                />
              ))
            )}
          </div>
        </div>

        {imagePreviewSrc && (
          <ImagePreviewModal
            imageSrc={imagePreviewSrc}
            onClose={() => setImagePreviewSrc(null)}
          />
        )}

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    )
  );
}

function CategoryRow({
  category,
  index,
  blogsList,
  onEdit,
  onDelete,
  onImageUpload,
  onPreviewImage,
  isEditing,
  isDeleting,
  onSaveEdit,
  onCancelEdit,
  hasPermission,
  project_id,
}) {
  const [editInput, setEditInput] = useState(category.title);

  useEffect(() => {
    if (!isEditing) setEditInput(category.title);
  }, [isEditing, category.title]);

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter") {
      onSaveEdit(category.id, editInput.trim());
    } else if (e.key === "Escape") {
      onCancelEdit();
    }
  };

  return (
    <div
      className={
        isEditing
          ? "flex items-center py-3 border-b last:border-none"
          : "border-b grid grid-cols-categories divide-x divide-gray-200 dark:divide-white/20 last:border-none"
      }
    >
      <p className={`py-2 px-3 ${isEditing && "w-28"}`}>{index + 1}</p>
      {isEditing ? (
        <input
          value={editInput}
          onChange={(e) => setEditInput(e.target.value)}
          onKeyDown={handleEditKeyDown}
          className="inputField py-[1px] mr-2 rounded-md"
          autoFocus
        />
      ) : (
        <span className="py-2 px-3 capitalize">{category.title}</span>
      )}

      {!isEditing && (
        <span className="py-2 px-5 text-center">
          {
            blogsList.filter(
              (item) => item?.article_category === category.title
            ).length
          }
        </span>
      )}

      {/* Actions */}
      <div className="flex items-center justify-center gap-2">
        {isEditing ? (
          <>
            <button
              onClick={() => onSaveEdit(category.id, editInput.trim())}
              className="btnPrimary py-[1px] gap-1 bg-secondary px-2"
            >
              <Check className="h-6 w-4 saveIcon" />
              Update
            </button>
            <button
              onClick={onCancelEdit}
              className="btnPrimary py-[1px] gap-1 bg-red-500 px-2"
            >
              <X className="h-6 w-4 cancelIcon" />
              Cancel
            </button>
          </>
        ) : (
          <>
            {hasPermission("Edit Project Category") && (
              <button onClick={() => onEdit(category)} className="ml-2">
                <Edit className="h-6 w-4 editIcon" />
              </button>
            )}
            {hasPermission("Delete Project Category") && (
              <DeleteAction
                category={category}
                isDeleting={isDeleting}
                onDelete={onDelete}
              />
            )}
          </>
        )}
      </div>
      {!isEditing && (
        <span className="p-1 gap-3 pr-2 flex items-center justify-between">
          <div className="overflow-hidden w-16 mx-auto h-6 rounded-md cursor-pointer scale-125">
            {category.image ? (
              <img
                src={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${category?.image}`}
                className="h-full object-cover min-w-full"
                alt="Category"
                onClick={() => onPreviewImage(category)}
              />
            ) : (
              <div className="h-full w-full bg-gray-200 flex items-center justify-center">
                <span className="text-xs text-gray-500">No Image</span>
              </div>
            )}
          </div>
          {/* Image Upload */}
          <Camera
            className="editIcon cursor-pointer"
            onClick={() => onImageUpload(category)}
          />
        </span>
      )}
    </div>
  );
}

// Delete category
const DeleteAction = ({ category, isDeleting, onDelete }) => {
  const ref = useRef(null);

  useClickOutside(ref, () => isDeleting && onDelete(null));

  return (
    <div className="relative">
      <Trash onClick={() => onDelete(category.id)} className="deleteIcon" />
      {isDeleting && (
        <div
          ref={ref}
          className="p-5 w-72 absolute top-0 right-0 mr-5 rounded-lg shadow-2xl border border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
        >
          <p className="font-medium">Please confirm to delete category</p>
          <div className="flex items-center justify-end mt-4">
            <button onClick={() => onDelete(null)} className="px-6">
              Cancel
            </button>
            <button
              onClick={() => onDelete(category.id, true)}
              className="btnPrimary bg-red-500"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Image preview modal
function ImagePreviewModal({ imageSrc, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-10">
      <X
        className="w-12 h-12 absolute top-0 right-0 m-10 text-white cursor-pointer"
        onClick={onClose}
      />
      <div className="bg-gray-200 p-4 rounded shadow-lg max-h-[calc(100vh-200px)] max-w-screen-lg overflow-hidden">
        <img
          src={imageSrc}
          alt="Preview"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
}
