import React, { useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  LoaderCircle,
  Trash,
  Upload,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { InputField, Modal } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import usePermissions from "../../../../utils/userPermission";
import ProjectMenu from "./ProjectMenu";
import ProjectIndentity from "./ProjectIndentity";
import ProjectSections from "../ProjectSections";
import NavStyles from "./LayoutStyles/NavStyles";
import LayoutStyles from "./LayoutStyles";

export default function ProjectSidebar({ open, setOpen }) {
  const navigate = useNavigate();
  const { project_id, projectToManage, getProjects } = useProjectContext();

  // Modal
  const [modalType, setModalType] = useState("");
  const [show, showModal] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    showModal(!show);
  };

  const { request } = useApi();
  const [confirmationText, setConfirmationText] = useState("");
  const deleteProject = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "delete",
        url: `projects/${project_id}`,
      });
      navigate(`/projects`);
      toast.success("Project Deleted");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const [isDeploying, setIsDeploying] = useState(false);
  const deployProject = async () => {
    setIsDeploying(true);
    try {
      await request({
        method: "get",
        url: `projects/${projectToManage?._id}/deploy`,
      });
      toast.success("Project Deployed");
      getProjects();
      handleModal();
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setIsDeploying(false);
  };

  const { hasPermission } = usePermissions();
  const [isCustomizing, setIsCustomizing] = useState("");

  const lastUpdatedAt = new Date(projectToManage.last_updated_at);
  const deployedOn = new Date(projectToManage.deployed_on);

  return (
    <div className="relative">
      {open && (
        <>
          <div className="w-64 bg-white dark:bg-gray-800 h-[calc(100vh-6rem)] overflow-y-scroll sticky top-0">
            {isCustomizing ? (
              <>
                {isCustomizing === "project identity" && (
                  <ProjectIndentity setIsCustomizing={setIsCustomizing} />
                )}
                {isCustomizing === "layout" && (
                  <ProjectSections setIsCustomizing={setIsCustomizing} />
                )}
                {isCustomizing === "layout_styles" && (
                  <LayoutStyles setIsCustomizing={setIsCustomizing} />
                )}
              </>
            ) : (
              <div className="flex flex-col justify-between h-full">
                <ProjectMenu setIsCustomizing={setIsCustomizing} />
                <div className="p-1 mt-6">
                  {hasPermission("deploy project") && (
                    <button
                      onClick={deployProject}
                      className={`btnDropdown px-3 hover:bg-primary hover:text-white group ${
                        isDeploying && "bg-primary text-white cursor-wait"
                      } ${
                        lastUpdatedAt.getTime() > deployedOn.getTime() &&
                        "bg-primary text-white"
                      }`}
                      disabled={isDeploying}
                    >
                      {isDeploying ? (
                        <LoaderCircle className="w-4 animate-spin text-primary group-hover:text-white" />
                      ) : (
                        <Upload
                          className={`w-4 text-gray-500 group-hover:text-white ${
                            lastUpdatedAt.getTime() > deployedOn.getTime() &&
                            "text-white"
                          }`}
                        />
                      )}
                      {isDeploying
                        ? "Publishing"
                        : projectToManage?.is_deployed
                        ? "Publish Changes"
                        : "Publish"}{" "}
                    </button>
                  )}
                  {hasPermission("delete project") && (
                    <button
                      onClick={() => {
                        setModalType("delete");
                        handleModal();
                      }}
                      className="btnDropdown px-3 border-none hover:bg-red-100 text-red-500"
                    >
                      <Trash className="w-4" /> Delete Project
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <div
        className={`h-[40vh] mt-[20vh] absolute flex items-center justify-center top-0 ${
          open ? "ml-64" : "ml-[6px]"
        }`}
      >
        <button
          onClick={() => setOpen(!open)}
          className={`bg-gray-500 hover:bg-gray-700 transition-all cursor-pointer rounded-full navDropdownButton h-10 ${
            open && "ml-1"
          }`}
        >
          <p className="navDropdown -mt-[5px]  bg-white text-black shadow-xl py-1 px-3 transition-all rounded-md transform ml-6 flex-col items-center z-20 w-fit fixed">
            {open ? "Full Screen" : "Exit Full Screen"}
          </p>
          {open ? (
            <ChevronLeft size={12} className="text-white" />
          ) : (
            <ChevronRight size={12} className="text-white" />
          )}
        </button>
      </div>

      {modalType === "delete" && (
        <Modal
          open={show}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-md"
          handleModalSubmit={deleteProject}
          modalType="delete"
          disabled={
            confirmationText?.toLowerCase() !==
            projectToManage?.project_name?.toLowerCase()
          }
          deleteButtonText="Delete This Project"
        >
          <p className="text-lg font-semibold">
            Are you sure you want delete this project?
          </p>
          <p className="text-red-500 mb-4 text-sm">
            This will permanently delete the {projectToManage?.project_name}{" "}
            project data, articles, logo, and all the tags.
          </p>
          <InputField
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            label={`To confirm, type "${projectToManage?.project_name}" in the box below`}
            className="mb-3"
          />
        </Modal>
      )}
      <Toaster />
    </div>
  );
}
