import React, { useEffect, useState, useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../../utils/useApi";
import { useProjectContext } from "../../ProjectContext";
import SectionHead from "../SectionHead";

export default function LayoutStyles({ setIsCustomizing }) {
  const { request } = useApi();
  const { project_id, refreshIframe } = useProjectContext();

  const [styles, setStyles] = useState({
    nav_type: { active: "", types: [] },
    blog_type: { active: "", types: [] },
    footer_type: { active: "", types: [] },
  });
  const [isUpdating, setUpdating] = useState(false);
  const [showOptions, setShowOptions] = useState(null);

  const fetchStyleData = useCallback(
    async (key) => {
      try {
        const res = await request({
          method: "get",
          url: `projects/${project_id}/data/${key}`,
        });

        if (res?.data[0]?.value) {
          setStyles((prevStyles) => ({
            ...prevStyles,
            [key]: res.data[0].value,
          }));
        } else {
          throw new Error("Data not found");
        }
      } catch (err) {
        console.error(`Failed to load ${key}:`, err);
        toast.error(`Failed to load ${key.replace("_", " ")} styles.`);
      }
    },
    [project_id, request]
  );

  useEffect(() => {
    ["nav_type", "blog_type", "footer_type"].forEach(fetchStyleData);
  }, [project_id]);

  const updateStyle = async (event, styleType, selectedStyle) => {
    event.preventDefault();
    setUpdating(true);

    const updatedStyles = { ...styles[styleType], active: selectedStyle };

    try {
      const formData = new FormData();
      formData.append("key", styleType);
      formData.append("value", JSON.stringify(updatedStyles));
      formData.append("value_type", "JSON");

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(
        `${styleType.replace("_", " ")} style updated successfully.`
      );
      setStyles((prevStyles) => ({
        ...prevStyles,
        [styleType]: updatedStyles,
      }));
      refreshIframe();
    } catch (err) {
      console.error(`Failed to update ${styleType}:`, err);
      toast.error(
        err.response?.data?.message ||
          `Error updating ${styleType.replace("_", " ")}.`
      );
    }

    setUpdating(false);
  };

  const renderStyleOptions = (styleType) => {
    const { active, types } = styles[styleType] || {};

    if (!types || types.length === 0) {
      return <p className="pl-5 text-gray-500">No styles available.</p>;
    }

    return (
      <div className="p-2">
        {types.map((item, index) => (
          <button
            key={index}
            onClick={(e) => updateStyle(e, styleType, item)}
            className={`btnDropdown border-gray-300 py-1 hover:bg-gray-500 hover:text-white capitalize ${
              item === active ? "bg-gray-500 text-white rounded-md" : ""
            }`}
            disabled={isUpdating}
          >
            {item.replaceAll("_", " ")}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div>
      <SectionHead title="Layout Styles" setIsCustomizing={setIsCustomizing} />
      <Toaster />

      {["nav_type", "blog_type", "footer_type"].map((styleType) => (
        <div
          key={styleType}
          className={showOptions === styleType && "bg-gray-200 rounded-md m-2"}
        >
          <button
            onClick={() =>
              setShowOptions(showOptions === styleType ? null : styleType)
            }
            className={`btnDropdown ${
              showOptions === styleType && "bg-primary text-white rounded-md"
            }`}
          >
            {`${
              styleType.replace("_", " ").charAt(0).toUpperCase() +
              styleType.slice(1)
            } Styles`}
          </button>
          {showOptions === styleType && renderStyleOptions(styleType)}
        </div>
      ))}
    </div>
  );
}
