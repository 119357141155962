import React, { useEffect, useRef, useState } from "react";
import { Check } from "lucide-react";
import { useTemplateContext } from "../../TemplateContext";
import toast from "react-hot-toast";
import useApi from "../../../../../utils/useApi";
import { useArticleContext } from "../ArticleContext";

export default function Dropdown({ blog, index }) {
  const { request } = useApi();
  const dropdownRef = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const { getBlogsList, blogsList } = useArticleContext();
  const { template_id, industry_id } = useTemplateContext();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBlogUpdate = async (blog, field) => {
    try {
      const limits = {
        isPopular: 3,
        isMustRead: 4,
        isFeatured: 1,
        trendingNews: 8,
        editorsChoice: 8,
      };

      const fieldCount = blogsList.filter((b) => b[field]).length;

      if (fieldCount >= limits[field] && !blog[field]) {
        const fieldLabels = {
          isPopular: "popular",
          isMustRead: "must read",
          isFeatured: "featured",
          trendingNews: "trending news",
          editorsChoice: "editor's choice",
        };
        toast.error(
          `You can only have up to ${limits[field]} ${fieldLabels[field]} articles.`
        );
        return;
      }

      blog[field] = !blog[field];

      const updatedBlogsList = blogsList.map((b) =>
        b.title.toLowerCase() === blog.title.toLowerCase() ? blog : b
      );

      const updatedFormData = new FormData();
      updatedFormData.append("key", "blog_list");
      updatedFormData.append("value_type", "JSON");
      updatedFormData.append("value", JSON.stringify(updatedBlogsList));

      await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: updatedFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      getBlogsList();

      const successLabels = {
        isPopular: "Popular",
        isMustRead: "Must Read",
        isFeatured: "Featured",
        trendingNews: "Trending News",
        editorsChoice: "Editor's Choice",
      };

      toast.success(
        `Article ${blog[field] ? "Added to" : "Removed from"} ${
          successLabels[field]
        } List.`
      );
    } catch (err) {
      console.error("Error updating blog:", err);
      toast.error("An error occurred while updating the blog.");
    }
  };

  return (
    <div className="relative flex flex-col">
      <button
        onClick={() => setDropdown(!dropdown)}
        className="cursor-pointer bg-primary text-sm px-2 py-0.5 rounded text-white"
      >
        Add To Sections
      </button>
      {dropdown && (
        <div
          ref={dropdownRef}
          className={`flex-col w-[200px] z-50 items-center absolute right-0 p-2 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl shadow-black/25 ${
            index < 2 ? "top-0 mt-6" : "bottom-0 mb-6"
          }`}
        >
          <button
            onClick={() => handleBlogUpdate(blog, "isPopular")}
            className="btnDropdown dark:bg-gray-900"
          >
            <div className="w-4 h-4 bg-gray-200 flex items-center justify-center border border-gray-400 rounded">
              {blog?.isPopular && <Check className="h-3 text-green-700" />}
            </div>
            Popular
          </button>
          <button
            onClick={() => handleBlogUpdate(blog, "isMustRead")}
            className="btnDropdown border-none dark:bg-gray-900"
          >
            <div className="w-4 h-4 bg-gray-200 flex items-center justify-center border border-gray-400 rounded">
              {blog?.isMustRead && <Check className="h-3 text-green-700" />}
            </div>
            Must Read
          </button>
          <button
            onClick={() => handleBlogUpdate(blog, "isFeatured")}
            className="btnDropdown border-none dark:bg-gray-900"
          >
            <div className="w-4 h-4 bg-gray-200 flex items-center justify-center border border-gray-400 rounded">
              {blog?.isFeatured && <Check className="h-3 text-green-700" />}
            </div>
            Feature Article
          </button>
          <button
            onClick={() => handleBlogUpdate(blog, "trendingNews")}
            className="btnDropdown border-none dark:bg-gray-900"
          >
            <div className="w-4 h-4 bg-gray-200 flex items-center justify-center border border-gray-400 rounded">
              {blog?.trendingNews && <Check className="h-3 text-green-700" />}
            </div>
            Trending News
          </button>
          <button
            onClick={() => handleBlogUpdate(blog, "editorsChoice")}
            className="btnDropdown border-none dark:bg-gray-900"
          >
            <div className="w-4 h-4 bg-gray-200 flex items-center justify-center border border-gray-400 rounded">
              {blog?.editorsChoice && <Check className="h-3 text-green-700" />}
            </div>
            Editor's Choice
          </button>
        </div>
      )}
    </div>
  );
}
