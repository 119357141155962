import React, { useRef, useState } from "react";
import { useTemplateContext } from "../TemplateContext";
import {
  ChevronLeft,
  ChevronRight,
  LoaderCircle,
  Trash,
  Upload,
} from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { InputField, Modal } from "../../../../components";
import usePermissions from "../../../../utils/userPermission";

export default function TemplateSidebar({ open, setOpen }) {
  const { ViewSwitch, temp, industry_id, template_id } = useTemplateContext();

  const { template } = useParams();
  const navigate = useNavigate();

  const viewData = () => {
    navigate(`/templates/${template}/data`);
  };

  const viewPreview = () => {
    navigate(`/templates/${template}`);
  };
  const visitSite = () => {
    const projectUrl = `https://${temp?.url}`;
    function navigate(url) {
      window.open(url, "_blank");
    }
    navigate(projectUrl);
  };

  const viewArticles = () => {
    navigate(`/templates/${template}/articles`);
  };
  const viewArticleCategories = () => {
    navigate(`/templates/${template}/article-categories`);
  };

  const viewSEO = () => {
    navigate(`/templates/${template}/seo`);
  };
  const viewGallery = () => {
    navigate(`/templates/${template}/gallery`);
  };
  const viewTempIndustries = () => {
    navigate(`/templates/${template}/industries`);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const { request } = useApi();

  const [isDeploying, setIsDeploying] = useState(false);
  const deploy = async (e) => {
    e.preventDefault();
    setIsDeploying(true);
    try {
      await request({
        method: "get",
        url: `/industries/${industry_id}/templates/${template_id}/deploy`,
      });
      toast.success(
        `Template "${temp?.template_name}" ${
          temp?.is_deployed ? "Redeployed" : "Deployed"
        } Successfully`
      );
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setIsDeploying(false);
  };

  const [show, showModal] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    showModal(!show);
  };

  const [confirmationText, setConfirmationText] = useState("");
  const deleteTemplate = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "delete",
        url: `industries/${industry_id}/templates/${template_id}`,
        data: { template_name: temp.template_name },
      });
      toast.success("Template Deleted");
      navigate(`/templates`);
    } catch (err) {
      toast.error(err);
    }
  };

  const { hasPermission } = usePermissions();

  return (
    <div className="relative">
      <Toaster />
      {open && (
        <div className="w-64 bg-white dark:bg-gray-800 h-[calc(100vh-6rem)] overflow-y-scroll sticky top-0 flex flex-col justify-between">
          <div>
            <div className="flex items-center justify-between px-2 py-1 border-b-2 dark:border-white/20 shadow-sm shadow-black/10">
              <p className="font-bold dark:text-white">Template Manager</p>
              <ViewSwitch />
            </div>
            {/* Datatable and Preview Buttons */}
            <div className="p-2">
              {hasPermission("template preview") && (
                <div
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith(template) &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  <button
                    className="flex items-center gap-3 flex-1"
                    onClick={() => viewPreview()}
                  >
                    Project Preview
                  </button>
                  <button
                    onClick={visitSite}
                    className={
                      (currentPath.endsWith(template)
                        ? "btnWhite text-black text-sm"
                        : "btnPrimary") +
                      " px-2 py-1 hover:bg-black hover:text-white transition-all"
                    }
                  >
                    On Web
                  </button>
                </div>
              )}
              {hasPermission("template tag manager") && (
                <button
                  onClick={() => viewData()}
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith("data") &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  Tag Manager
                </button>
              )}
              {hasPermission("template articles") && (
                <button
                  onClick={() => viewArticles()}
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith("articles") &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  Manage Articles
                </button>
              )}
              {hasPermission("view article categories") && (
                <button
                  onClick={() => viewArticleCategories()}
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith("categories") &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  Article Categories
                </button>
              )}
              {hasPermission("template seo") && (
                <button
                  onClick={() => viewSEO()}
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith("seo") &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  SEO
                </button>
              )}
              {hasPermission("template tag manager") && (
                <button
                  onClick={() => viewGallery()}
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith("gallery") &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  Gallery
                </button>
              )}
              {/* {temp?.industry_id?.industry_name === "business" && (
                <button
                  onClick={() => viewTempIndustries()}
                  className={`btnDropdown px-3 justify-between ${
                    currentPath.endsWith("industries") &&
                    "bg-primary hover:bg-primary text-white rounded-md"
                  }`}
                >
                  Industries
                </button>
              )} */}
            </div>
          </div>
          <div className="p-2">
            {hasPermission("deploy template") && (
              <button
                onClick={deploy}
                className={`btnDropdown px-3 hover:bg-black hover:text-white ${
                  isDeploying && "bg-black text-white"
                }`}
              >
                {isDeploying ? (
                  <LoaderCircle className="w-4 animate-spin text-primary" />
                ) : (
                  <Upload className="w-4 text-gray-500" />
                )}
                {isDeploying
                  ? "Deploying"
                  : temp?.is_deployed
                  ? "Redeploy Template"
                  : "Deploy Template"}{" "}
              </button>
            )}
            {hasPermission("Delete Template") && (
              <button
                className="btnDropdown border-none px-3"
                onClick={handleModal}
              >
                <Trash className="h-4 w-4" /> Delete Template
              </button>
            )}
          </div>
        </div>
      )}

      <div
        className={`h-[60vh] absolute flex items-center justify-center top-0 ${
          open ? "ml-64" : "ml-[6px]"
        }`}
      >
        <button
          onClick={() => setOpen(!open)}
          className={`bg-gray-500 hover:bg-gray-700 transition-all cursor-pointer rounded-full navDropdownButton h-10 ${
            open && "ml-1"
          }`}
        >
          <p className="navDropdown -mt-[5px]  bg-white text-black shadow-xl py-1 px-3 transition-all rounded-md transform ml-6 flex-col items-center z-20 w-fit fixed">
            {open ? "Full Screen" : "Exit Full Screen"}
          </p>
          {open ? (
            <ChevronLeft size={12} className="text-white" />
          ) : (
            <ChevronRight size={12} className="text-white" />
          )}
        </button>
      </div>

      <Modal
        open={show}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-md"
        handleModalSubmit={deleteTemplate}
        modalType="delete"
        disabled={
          confirmationText?.toLowerCase() !== temp?.template_name?.toLowerCase()
        }
        deleteButtonText="Delete This Template"
      >
        <p className="text-lg font-semibold">
          Are you sure you want delete this template?
        </p>
        <p className="text-red-500 mb-4 text-sm">
          This will permanently delete the {temp?.template_name} template data,
          articles, logo, and all the tags.
        </p>
        <InputField
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          label={`To confirm, type "${temp?.template_name}" in the box below`}
          className="mb-3"
        />
      </Modal>
    </div>
  );
}
