import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProjectContext } from "../ProjectContext";
import usePermissions from "../../../../utils/userPermission";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { toast } from "react-hot-toast";
import {
  BookUser,
  Box,
  Check,
  ChevronDown,
  Contact,
  Dock,
  Edit,
  GalleryThumbnails,
  GlobeLock,
  Images,
  LayoutList,
  LayoutPanelTop,
  LineChart,
  ListTree,
  Newspaper,
  NewspaperIcon,
  Podcast,
  ReceiptTextIcon,
  Tag,
  User,
  X,
} from "lucide-react";

const getIconClassName = (currentPath, pathEnd) =>
  `w-4 text-gray-500 ${currentPath.endsWith(pathEnd) ? "text-white" : ""}`;

const ProjectMenu = ({ setIsCustomizing }) => {
  const { project } = useParams();
  const navigate = useNavigate();
  const { projectToManage } = useProjectContext();
  const location = useLocation();
  const currentPath = location.pathname;
  const { hasPermission } = usePermissions();
  const [customize, setCustomize] = useState(false);
  const [editProjectName, setEditProjectName] = useState(false);
  const [project_name, setProjectName] = useState("");
  const { request } = useApi();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateProjectInfo(e);
    }
  };

  const handleEditProject = () => {
    setEditProjectName(true);
    setProjectName(projectToManage?.project_name);
  };

  const updateProjectInfo = async (e) => {
    e.preventDefault();

    try {
      await toast.promise(
        request({
          method: "put",
          url: `projects/${projectToManage?._id}`,
          data: {
            project_name,
            industry_id: projectToManage?.industry_id?._id,
            industry_template_id: projectToManage?.industry_template_id?._id,
          },
        }),
        {
          loading: "Updating project name...",
          success: <b>Project Name Updated!</b>,
          error: <b>Operation could not be performed, some error occurred.</b>,
        }
      );

      setEditProjectName(false);
      setProjectName("");
      navigate(`/projects/${project_name?.toLowerCase().replaceAll(" ", "-")}`);
      setTimeout(() => window.location.reload(), 300);
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const renderButton = (label, onClick, permission, IconComponent, pathEnd) =>
    hasPermission(permission) && (
      <button
        key={label}
        onClick={onClick}
        className={`btnDropdown px-3 ${
          currentPath.endsWith(pathEnd) &&
          "bg-primary hover:bg-primary text-white rounded-md"
        }`}
      >
        <IconComponent className={getIconClassName(currentPath, pathEnd)} />
        {label}
      </button>
    );

  return (
    <div>
      <div className="relative flex items-center justify-between gap-2 px-4 py-2 border-b-2 dark:border-white/20 shadow-sm shadow-black/10">
        {editProjectName ? (
          <InputField
            inputStyle="p-1"
            placeholder="Enter project name"
            value={project_name}
            onKeyDown={handleKeyDown}
            onChange={(e) => setProjectName(e.target.value)}
          />
        ) : (
          <p className="font-bold dark:text-white capitalize">
            {projectToManage?.project_name}
          </p>
        )}
        {editProjectName ? (
          <div className="flex items-center gap-2">
            <button
              onClick={updateProjectInfo}
              className="bg-white w-6 h-6 ml-2 rounded flex items-center justify-center shadow-md hover:bg-secondary hover:text-white transition-all"
            >
              <Check className="w-4 h-4" />
            </button>
            <button
              onClick={() => setEditProjectName(false)}
              className="bg-white w-6 h-6 rounded flex items-center justify-center shadow-md hover:bg-red-500 hover:text-white transition-all"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ) : (
          <Edit className="editIcon" onClick={handleEditProject} />
        )}
      </div>

      <div className="px-1">
        <div className="grid grid-cols-3 border-b py-1">
          <button
            className={`text-center pb-2 py-1 hover:text-primary hover:underline transition-all border-r ${
              currentPath.endsWith(project) && "text-primary underline rounded"
            }`}
            onClick={() => navigate(`/projects/${project}`)}
          >
            Preview
          </button>
          <a
            href={`https://${projectToManage?.project_name?.replaceAll(
              " ",
              "-"
            )}.sitebuilderz.com`}
            target="_blank"
            rel="noreferrer"
            className="text-center pb-2 py-1 hover:text-primary hover:underline transition-all border-r"
          >
            Staging
          </a>
          {projectToManage?.domain_id?.domain && (
            <a
              href={`https://${projectToManage?.domain_id?.domain}`}
              target="_blank"
              rel="noreferrer"
              className="text-center pb-2 py-1 hover:text-primary hover:underline transition-all"
            >
              On Web
            </a>
          )}
        </div>

        {renderButton(
          "Manage Domain",
          () => navigate(`/projects/${project}/manage-domain`),
          "Manage Domain",
          Box,
          "manage-domain"
        )}
        {renderButton(
          "Tag Manager",
          () => navigate(`/projects/${project}/data`),
          "project tag manager",
          Tag,
          "data"
        )}
        {renderButton(
          "Project Articles",
          () => navigate(`/projects/${project}/articles`),
          "project articles",
          Newspaper,
          "articles"
        )}
        {renderButton(
          "Project Categories",
          () => navigate(`/projects/${project}/project-categories`),
          "View Project Categories",
          ListTree,
          "project-categories"
        )}
        {renderButton(
          "SEO",
          () => navigate(`/projects/${project}/seo`),
          "project seo",
          LineChart,
          "seo"
        )}

        {hasPermission("customize project") && (
          <div
            onClick={() => setCustomize(!customize)}
            className="btnDropdown px-3 justify-between cursor-pointer"
          >
            <div className="flex items-center gap-3">
              <LayoutPanelTop className="w-4 text-gray-500" /> Customize Project
            </div>
            <ChevronDown
              className={`w-4 transition-all ${
                customize ? "rotate-0" : "rotate-180"
              }`}
            />
          </div>
        )}

        {customize && (
          <div className="pl-5">
            <button
              onClick={() => {
                setIsCustomizing("project identity");
                navigate(`/projects/${project}`);
              }}
              className="btnDropdown px-3"
            >
              <Dock className="w-4 text-gray-500" />
              Project Identity
            </button>
            {hasPermission("View layout") && (
              <button
                onClick={() => {
                  setIsCustomizing("layout");
                  navigate(`/projects/${project}`);
                }}
                className="btnDropdown px-3"
              >
                <LayoutList className="w-4 text-gray-500" />
                Project Layout
              </button>
            )}
            {hasPermission("View layout") && (
              <button
                onClick={() => {
                  setIsCustomizing("layout_styles");
                  navigate(`/projects/${project}`);
                }}
                className="btnDropdown px-3"
              >
                <Dock className="w-4 text-gray-500" />
                Layout Styles
              </button>
            )}
            {renderButton(
              "Banner",
              () => navigate(`/projects/${project}/banner`),
              "project banner",
              GalleryThumbnails,
              "banner"
            )}
            {renderButton(
              "Contact Details",
              () => navigate(`/projects/${project}/contact-details`),
              "customize project",
              Contact,
              "contact-details"
            )}
            {renderButton(
              "About Us",
              () => navigate(`/projects/${project}/about-us`),
              "customize project",
              Podcast,
              "about-us"
            )}
            {renderButton(
              "Terms & Conditions",
              () => navigate(`/projects/${project}/terms`),
              "customize project",
              NewspaperIcon,
              "terms"
            )}
            {renderButton(
              "Privacy Policy",
              () => navigate(`/projects/${project}/policy`),
              "customize project",
              GlobeLock,
              "privacy"
            )}
          </div>
        )}

        {renderButton(
          "Gallery",
          () => navigate(`/projects/${project}/gallery`),
          "view images",
          Images,
          "gallery"
        )}
      </div>
    </div>
  );
};

export default ProjectMenu;
