import React, { useState, useMemo } from "react";
import Header from "./Header";
import ArticleCard from "./ArticleCard";
import { LoaderCircle } from "lucide-react";
import { useArticleContext } from "./ArticleContext";

const ArticlesList = () => {
  const {
    grid,
    blogsList,
    loadingArticles,
    deleteConfirmation,
    setDeleteConfirmation,
  } = useArticleContext();

  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");

  const filteredArticles = useMemo(() => {
    return (
      blogsList
        ?.filter((blog) => {
          const matchesSearch =
            searchTerm === "" ||
            blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            blog.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
            blog?.article_category
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          const matchesCategory =
            filterCategory === "" ||
            blog?.article_category === filterCategory ||
            (filterCategory === "Must Read" && blog.isMustRead) ||
            (filterCategory === "Popular" && blog.isPopular);
          return matchesSearch && matchesCategory;
        })
        .reverse() || []
    );
  }, [blogsList, searchTerm, filterCategory]);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  return (
    <div>
      <Header
        paginate={paginate}
        currentPage={currentPage}
        totalPages={totalPages}
        searchTerm={searchTerm}
        setSearchTerm={(e) => setSearchTerm(e.target.value)}
        filterCategory={filterCategory}
        setFilterCategory={setFilterCategory}
      />
      {loadingArticles ? (
        <div className="flex flex-col items-center w-full p-24 gap-5">
          <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
          <p>Loading Articles...</p>
        </div>
      ) : (
        <div className="flex flex-col items-center w-full">
          {filteredArticles.length > 0 ? (
            <div
              className={`grid grid-cols-1 ${
                grid
                  ? "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6"
                  : "gap-4"
              } w-full mt-6`}
            >
              {currentArticles.map((blog, index) => (
                <ArticleCard
                  key={blog.key}
                  blog={blog}
                  deleteConfirmation={deleteConfirmation}
                  setDeleteConfirmation={setDeleteConfirmation}
                  index={index}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center w-full p-40 text-white">
              No Articles
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticlesList;
