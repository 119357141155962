import React, { useEffect, useState, useCallback, useMemo } from "react";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { useProjectContext } from "../ProjectContext";
import { InputField, TextArea } from "../../../../components";
import { CameraIcon, LoaderCircle, Save } from "lucide-react";

export default function ProjectBanner() {
  const { request } = useApi();
  const { project_id } = useProjectContext();

  const fontSizeOptions = [
    "12px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "48px",
    "64px",
    "72px",
    "80px",
    "96px",
  ];

  const [bannerData, setBannerData] = useState({
    title: "",
    tagline: "",
    imageTitle: "",
    altImage: "",
    opacity: "",
    textColor: "#FFFFF",
    titleFontSize: "32px",
    taglineFontSize: "18px",
  });

  const [fileInfo, setFileInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const getBannerData = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/banner`,
      });

      const bannerValues = res.data?.[0]?.value || {};
      const file = res.data?.[0]?.file_name;

      setBannerData(bannerValues);

      setFileInfo({
        file,
        preview: file
          ? `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${file}`
          : null,
      });
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch banner data.");
    }
  }, [project_id, request]);

  useEffect(() => {
    getBannerData();
  }, [project_id]);

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setFileInfo({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setBannerData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const updateKeyValue = useCallback(
    async (event) => {
      event.preventDefault();
      setIsUpdating(true);

      try {
        const formData = new FormData();
        formData.append("key", "banner");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(bannerData));

        if (fileInfo?.file) {
          formData.append("file", fileInfo.file);
        }

        await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Banner Data updated.");
        getBannerData();
      } catch (err) {
        const errorMsg =
          err.response?.data?.message ||
          "Operation could not be performed, some error occurred.";
        toast.error(errorMsg);
      } finally {
        setIsUpdating(false);
      }
    },
    [bannerData, fileInfo, getBannerData, project_id, request]
  );

  const bannerStyle = useMemo(
    () => ({
      backgroundColor: `rgba(0, 0, 0, ${bannerData.opacity / 100})`,
      color: bannerData.textColor,
    }),
    [bannerData.opacity, bannerData.textColor]
  );

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 py-6 flex flex-col items-center">
      <Toaster />
      <div className="max-w-6xl w-full">
        <h2 className="font-bold">Project Banner</h2>
        <div className="rounded-xl p-10 bg-white dark:bg-gray-800 h-fit mt-6">
          <div className="overflow-hidden relative h-96 transition-all w-full rounded-md bg-gray-300 border-white/20 flex items-center justify-center">
            <img
              title="Upload an image"
              src={fileInfo?.preview || ""}
              loading="lazy"
              alt={bannerData.altImage || "Project Banner"}
              className={`w-full h-full object-cover absolute top-0 ${
                fileInfo ? "scale-100" : "scale-125"
              }`}
            />
            <div
              className="flex flex-col items-center justify-center z-50 w-full h-full"
              style={bannerStyle}
            >
              <div className="flex items-center flex-col w-full max-w-3xl">
                <TextArea
                  name="title"
                  value={bannerData.title}
                  onChange={handleChange}
                  inputStyle="text-center bg-transparent font-bold border-none"
                  style={{
                    color: bannerData.textColor,
                    fontSize: bannerData.titleFontSize,
                    height: "auto",
                    lineHeight: "normal",
                    padding: 0,
                  }}
                />
                <TextArea
                  name="tagline"
                  value={bannerData.tagline}
                  onChange={handleChange}
                  inputStyle="text-center bg-transparent border-none"
                  style={{
                    color: bannerData.textColor,
                    fontSize: bannerData.taglineFontSize,
                    height: "auto",
                    lineHeight: "normal",
                    padding: 0,
                  }}
                />
              </div>

              <input
                type="file"
                onChange={handleFileChange}
                id="imageUpload"
                className="hidden"
                accept="image/*"
              />
              <label
                htmlFor="imageUpload"
                className="btnWhite py-1 px-3 cursor-pointer text-black hover:bg-primary hover:text-white transition-all flex items-center gap-2 z-50 m-2 absolute right-0 bottom-0 h-fit"
              >
                Upload Image
                <CameraIcon className="w-4" />
              </label>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 mt-7">
            <InputField
              name="imageTitle"
              label="Banner Image Title"
              placeholder="Banner Image Title"
              value={bannerData.imageTitle}
              onChange={handleChange}
            />
            <InputField
              name="altImage"
              label="Image Alt Text"
              placeholder="Image alt text"
              value={bannerData.altImage}
              onChange={handleChange}
            />
            <div>
              <p className="inputLabel mb-1">
                Background Opacity{" "}
                <span className="font-normal text-primary">
                  ({bannerData.opacity})
                </span>{" "}
              </p>
              <div className="w-full flex flex-col items-center border dark:border-white/20 rounded justify-center py-4 px-5">
                <input
                  type="range"
                  min="0"
                  max="100"
                  name="opacity"
                  value={bannerData.opacity}
                  onChange={handleChange}
                  className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg cursor-pointer"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3">
              <div>
                <p className="inputLabel mb-1">Text Color</p>
                <input
                  type="color"
                  name="textColor"
                  value={bannerData.textColor}
                  onChange={handleChange}
                  className="w-full h-10 p-1 border dark:border-white/20 rounded cursor-pointer"
                />
              </div>
              <div>
                <p className="inputLabel mb-1">Title Font Size</p>
                <select
                  name="titleFontSize"
                  value={bannerData.titleFontSize}
                  onChange={handleChange}
                  className="inputField py-[9px]"
                >
                  {fontSizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p className="inputLabel mb-1">Tagline Font Size</p>
                <select
                  name="taglineFontSize"
                  value={bannerData.taglineFontSize}
                  onChange={handleChange}
                  className="inputField py-[9px]"
                >
                  {fontSizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex-1">
              <p className="inputLabel mb-1">Banner Styles</p>
              <select
                name="active"
                value={bannerData.active}
                onChange={handleChange}
                className="inputField py-[9px] capitalize"
              >
                {bannerData?.types?.map((style, index) => (
                  <option className="capitalize" key={index} value={style}>
                    {style?.replaceAll("_", " ")}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex items-center justify-end mt-10 gap-3">
            <button
              title="Save & Update"
              onClick={updateKeyValue}
              className={`btnPrimary bg-secondary px-3 ${
                isUpdating && "cursor-wait"
              }`}
              disabled={isUpdating}
            >
              {isUpdating ? (
                <LoaderCircle className="w-4 animate-spin" />
              ) : (
                <Save className="w-4 h-4" />
              )}
              {isUpdating ? <p>Updating Banner</p> : <p>Save & Update</p>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
