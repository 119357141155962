import { Camera, LoaderCircle, Save } from "lucide-react";
import React, { useEffect, useRef } from "react";
import { InputField } from "../../../../components";
import dayjs from "dayjs";

export default function AddUpdateImage({
  sidebar,
  toggleSidebar,
  handleChange,
  isUpdating,
  createUpdate,
  handleFileChange,
  fileInfo,
  image,
}) {
  const addFromRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addFromRef.current && !addFromRef.current.contains(event.target)) {
        if (sidebar) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar, toggleSidebar]);

  return (
    <div
      ref={addFromRef}
      className={`flex flex-col gap-6 w-[600px] min-h-screen z-50 fixed right-0 top-0 p-6 bg-white dark:bg-gray-800 capitalize ${
        sidebar && "shadow-l shadow-black/20 dark:shadow-gray-600/40"
      }`}
      style={{ transform: sidebar ? "translateX(0)" : "translateX(100%)" }}
    >
      <h3 className="font-bold">Attachment Detail</h3>
      <div className="overflow-hidden relative h-96 transition-all w-full rounded-md bg-gray-300 border border-white/20 flex items-center justify-end">
        <input
          type="file"
          onChange={handleFileChange}
          id="imageUpload"
          className="hidden"
          accept="image/*"
        />
        <div className="flex justify-end z-10 p-5 gap-3 absolute right-0 bottom-0 h-fit w-full bg-gradient-to-b from-black/5 to-black">
          <label
            htmlFor="imageUpload"
            className="btnWhite py-1 px-3 cursor-pointer flex items-center gap-2"
          >
            <Camera className="w-4" />
            Upload Image
          </label>
        </div>
        <img
          title={"Upload an image"}
          src={fileInfo?.preview}
          loading="lazy"
          alt=""
          className={`w-full h-full object-cover absolute top-0 ${
            fileInfo ? "scale-100" : "scale-125"
          }`}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputField
          label="Image Title"
          name="imageTitle"
          placeholder="image title"
          value={image?.imageTitle || ""}
          onChange={handleChange}
        />
        <InputField
          label="Alt Text"
          name="imageAltText"
          placeholder="image alt text"
          value={image?.imageAltText || ""}
          onChange={handleChange}
        />
      </div>
      <div>
        {image.createdAt && (
          <p>
            <span className="font-semibold">Created At: </span>
            {dayjs(image.createdAt).format("ddd, MMM D, YYYY h:mm A")}
          </p>
        )}
        {image.createdAt && (
          <p>
            <span className="font-semibold">Updated At: </span>
            {dayjs(image.updatedAt).format("ddd, MMM D, YYYY h:mm A")}
          </p>
        )}
        {image.fileSize && (
          <p>
            <span className="font-semibold">File Size: </span>
            {image.fileSize}
          </p>
        )}
        {image.fileType && (
          <p>
            <span className="font-semibold">File Type: </span>
            {image.fileType}
          </p>
        )}
        {image.dimensions && (
          <p>
            <span className="font-semibold">Dimensions: </span>
            {image.dimensions}
          </p>
        )}
      </div>
      <div className="flex items-center justify-end gap-2 mt-6">
        <button onClick={toggleSidebar} className="px-4">
          Cancel
        </button>
        <button
          type="button"
          disabled={isUpdating}
          onClick={createUpdate}
          className="btnPrimary px-3 bg-green-600 disabled:cursor-default disabled:opacity-90 disabled:bg-primary"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          {isUpdating ? <p>Updating Image</p> : <p>Save & Update</p>}
        </button>
      </div>
    </div>
  );
}
