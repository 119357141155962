import React, { createContext, useState, useContext, useEffect } from "react";
import useApi from "../../../utils/useApi";
import { useNavigate, useParams } from "react-router-dom";
import { LaptopMinimalIcon, Smartphone } from "lucide-react";

const ProjectContext = createContext();
export const useProjectContext = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const { request, isLoading } = useApi();
  const { project } = useParams();
  const [projects, setProjects] = useState([]);
  const [projectToManage, setProjectToManage] = useState({});
  const navigate = useNavigate();

  const getProjects = async () => {
    try {
      const res = await request({
        method: "get",
        url: "projects",
      });
      setProjects(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const getProject = async () => {
    const prj = projects.find((item) => item.slug === project);
    if (prj) {
      setProjectToManage(prj);
    } else {
      navigate(`/projects`);
    }
  };

  // console.log("🚀🚀 Project ~ ProjectContext.js ~ 🚀🚀 ", projectToManage);

  useEffect(() => {
    if (projects.length > 0) {
      getProject();
    }
  }, [projects]);

  const [isPhoneView, setIsPhoneView] = useState(false);
  const ViewSwitch = () => (
    <div className="flex items-center justify-center dark:border-white/40 bg-white/20 rounded">
      <button
        className={`h-7 px-3 flex items-center justify-center transition-all rounded ${
          !isPhoneView
            ? "bg-primary text-white dark:bg-primary/80"
            : "border-gray-800 dark:hover:bg-gray-700 dark:hover:border-gray-700"
        }`}
      >
        <LaptopMinimalIcon
          className="w-4"
          onClick={() => setIsPhoneView(false)}
        />
      </button>
      <button
        className={`h-7 px-3 flex items-center justify-center transition-all rounded ${
          isPhoneView
            ? "bg-primary text-white dark:bg-primary/80"
            : "border-gray-800 dark:hover:bg-gray-700 dark:hover:border-gray-700"
        }`}
        onClick={() => setIsPhoneView(true)}
      >
        <Smartphone className="w-4" />
      </button>
    </div>
  );

  const [iframeURL, setIframeURL] = useState("");
  useEffect(() => {
    setIframeURL(
      `https://${projectToManage?.project_name?.replaceAll(
        " ",
        "-"
      )}.sitebuilderz.com`
    );
  }, [projectToManage?._id]);

  const [iframeKey, setIframeKey] = useState(0);
  const refreshIframe = () => {
    setIframeKey((prevKey) => prevKey + 1);
    getProjects();
  };

  return (
    <ProjectContext.Provider
      value={{
        projectToManage,
        projects,
        setProjects,
        project_id: projectToManage?._id,
        template_id: projectToManage?.industry_template_id?._id,
        projectLoading: isLoading,
        getProject,
        ViewSwitch,
        isPhoneView,
        iframeKey,
        refreshIframe,
        iframeURL,
        setIframeURL,
        getProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
