import React from "react";
import { useArticleContext } from "./ArticleContext";
import {
  ChevronLeft,
  ChevronRight,
  Grid2X2,
  List,
  RefreshCw,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import usePermissions from "../../../../utils/userPermission";

export default function Header({
  searchTerm,
  setSearchTerm,
  filterCategory,
  setFilterCategory,
  currentPage,
  paginate,
  totalPages,
}) {
  const navigate = useNavigate();
  const { template } = useParams();
  const {
    getBlogsList,
    isLoading,
    grid,
    setGrid,
    setArticle,
    setFileInfo,
    categories,
  } = useArticleContext();

  const { hasPermission } = usePermissions();

  const writeArticle = () => {
    navigate(`/templates/${template}/articles/write`);
  };

  const GridBox = ({ className }) => (
    <div
      className={`bg-white dark:bg-gray-800 p-1 rounded-md border border-gray-300 dark:border-white/30 flex items-center justify-center ${className}`}
    >
      <button
        onClick={() => setGrid(true)}
        className={`cursor-pointer rounded hover:bg-gray-200 dark:hover:bg-gray-900 py-1 px-4 ${
          grid && "bg-gray-200 dark:bg-gray-900"
        }`}
      >
        <Grid2X2 className="h-5 w-5" />
      </button>
      <button
        onClick={() => setGrid(false)}
        className={`cursor-pointer rounded hover:bg-gray-200 dark:hover:bg-gray-900 py-1 px-4 ${
          !grid && "bg-gray-200 dark:bg-gray-900"
        }`}
      >
        <List className="h-5 w-5" />
      </button>
    </div>
  );

  const AddArticle = ({ className }) => (
    <button
      className={`btnPrimary ${className}`}
      onClick={() => {
        setArticle({
          title: "",
          tagline: "",
          author: "",
          published_at: "",
          imageTitle: "",
          image: "",
          articleContent: "",
        });
        setFileInfo(null);
        writeArticle();
      }}
    >
      Add Article
    </button>
  );

  const Pagination = () => (
    <div className="flex justify-center items-center">
      <button
        disabled={currentPage === 1}
        className="h-8 w-8 rounded-full hover:bg-white hover:text-black transition-all flex items-center justify-center"
        onClick={() => paginate(currentPage - 1)}
      >
        <ChevronLeft className="w-5 h-5" />
      </button>

      {[...Array(totalPages).keys()].map((number) => (
        <button
          key={number}
          onClick={() => paginate(number + 1)}
          className={`h-8 w-8 rounded-full ${
            currentPage === number + 1
              ? "bg-primary text-white"
              : "hover:bg-white hover:text-black"
          }`}
        >
          {number + 1}
        </button>
      ))}
      <button
        disabled={currentPage === totalPages}
        className="h-8 w-8 rounded-full hover:bg-white hover:text-black transition-all flex items-center justify-center"
        onClick={() => paginate(currentPage + 1)}
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    </div>
  );

  return (
    <div className="flex flex-col xl:flex-row lg:items-center gap-3 lg:gap-2 w-full">
      <div className="flex items-center flex-wrap md:flex-nowrap w-full lg:w-auto">
        <div className="flex items-center justify-between">
          <p className="text-xl font-bold whitespace-nowrap">Articles </p>
          <button onClick={getBlogsList}>
            <RefreshCw
              className={`w-5 cursor-pointer dark:text-white/80 mx-3 ${
                isLoading && "animate-spin"
              }`}
            />
          </button>
          <p className="mx-2 whitespace-nowrap">
            Page {currentPage} of {totalPages}
          </p>
          <Pagination />
        </div>
        <div className="flex lg:hidden items-center justify-end gap-2">
          <GridBox />
          {hasPermission("Add Template Article") && <AddArticle />}
        </div>
      </div>
      <div className="flex items-center gap-2 lg:flex-1 w-full">
        <div className="flex items-center border dark:border-white/20 gap-3 bg-white dark:bg-gray-800 w-full rounded-md">
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="bg-transparent py-2 px-3 w-fit"
          >
            <option value="">All Categories</option>
            {categories?.map((item, index) => (
              <option key={index} value={item?.title}>
                {item?.title}
              </option>
            ))}
            <option value="Must Read">Must Read</option>
            <option value="Popular">Popular</option>
          </select>
          <input
            type="text"
            className="bg-transparent flex-1 outline-none border-l dark:border-white/20 px-5 py-1"
            placeholder="Search article.."
            value={searchTerm}
            onChange={setSearchTerm}
          />
        </div>
        <div className="hidden lg:flex items-center justify-end gap-2">
          <GridBox />
          {hasPermission("Add Template Article") && <AddArticle />}
        </div>
      </div>
    </div>
  );
}
